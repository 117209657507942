<template>
  <!-- 判断是否注册 -->
  <div v-if="!is_register">
    <!-- <div class="d-flex" style="margin-bottom: 20px"> -->
    <!-- 返回 -->
    <el-row>
      <el-col :xs="24" :sm="6">
        <el-button
          icon="el-icon-arrow-left"
          class="mr-3 mb-2"
          style="color: #50b5ff; height: 41px"
          @click="$router.back()"
          >{{ $t("Events.024@back") }}</el-button
        >
      </el-col>
      <el-col :xs="24" :sm="18">
        <el-alert
          style="color: #ffba68"
          class="mb-2"
          show-icon
          center
          :title="$t('Events.be4@you_have_not_registe')"
          type="warning"
        >
        </el-alert>
      </el-col>
    </el-row>
    <!-- </div> -->
    <el-card>
      <div style="background:#fff;">
        <img
          src="@/assets/images/group/empty1.png"
          alt=""
          style="width:250px; margin: 100px auto; display: block"
        />
      </div>
    </el-card>
  </div>
  <section v-else>
    <el-row>
      <el-col :xs="24" :sm="18">
        <div class="program container690">
          <el-button
            icon="el-icon-arrow-left"
            style="color: #50b5ff; height: 41px; margin-bottom: 20px"
            @click="$router.back()"
          >
            {{ $t("Events.024@back") }}</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="24" :sm="18">
        <div class="program container690" v-if="!Array.isArray(mySchedule)">
          <el-card>
            <div class="program-header d-flex justify-content-between">
              <div class="program-btn">
                <el-button
                  :type="index ? 'primary' : 'info'"
                  plain
                  :disabled="!index"
                  style="border: none"
                  @click="changeIndex(0)"
                >
                  {{ $t("Events.99e@previous") }}</el-button
                >
              </div>
              <div class="d-flex">
                <i class="ri-calendar-2-line"></i>
                <p class="m-0">
                  {{ Object.keys(mySchedule)[index] }}
                  <span style="color: #b1b6ba"
                    >({{
                      mySchedule[Object.keys(mySchedule)[index]] &&
                      mySchedule[Object.keys(mySchedule)[index]][0].time_zone_2
                    }})</span
                  >
                </p>
              </div>
              <div class="program-btn">
                <el-button
                  :type="
                    index != Object.keys(mySchedule).length - 1
                      ? 'primary'
                      : 'info'
                  "
                  plain
                  :disabled="index == Object.keys(mySchedule).length - 1"
                  style="border: none; width: 90px"
                  @click="changeIndex(1)"
                  >{{ $t("Events.b72@next") }}
                </el-button>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 20px">
            <section
              v-for="(v, i) in mySchedule[Object.keys(mySchedule)[index]]"
              :key="i"
              class="program-content"
            >
              <p class="m-0">
                {{ v.time1 }}
                <span style="color: #b1b6ba">({{ v.time_zone_2 }})</span>
              </p>
              <ul class="p-0 program-list">
                <li>
                  <div>
                    <h3 style="cursor: pointer" @click="toProgramDetail(v)">
                      {{ v.program_title }}
                      <span
                        :class="{
                          MeetingTag: v.model_type == 4,
                          EventTag: v.model_type == 2,
                          courseTag: v.model_type == 3,
                          projectTag: v.model_type == 1,
                          noneTag: v.model_type == 0,
                        }"
                        class="tag"
                        v-if="v.is_cron"
                        >{{ judgeCurrentType(v) }}</span
                      >
                    </h3>
                    <span
                      ><i class="ri-time-line"></i>{{ v.time }}
                      <span style="color: #b1b6ba">({{ v.time_zone }})</span>
                    </span>
                    <section v-if="!(v.model_type == 3 && v.package_type == 1)">
                      <span
                        v-if="v.type != 1"
                        class="d-flex align-items-center"
                      >
                        <i
                          v-if="v.broadcast_link || v.conference_link"
                          class="ri-link"
                        ></i>
                        <div class="d-flex flex-column">
                          <span
                            v-if="v.broadcast_link"
                            @click="userHasPowerClickLink(v)"
                            class="link_blue"
                            >{{ $t("Events.dcf@click_here_to_view") }}</span
                          >
                          <a
                            class="link_blue mt-2"
                            @click="conferLinkLink(v.conference_link)"
                            v-if="v.conference_link"
                            target="__blank"
                            >{{ $t("Events.e6e@click_here_to_join_c") }}</a
                          >
                        </div>
                      </span>
                      <span v-if="v.type != 2 && (v.room || v.venue)"
                        ><i class="ri-map-pin-line"></i>{{ v.venue }}
                        {{ v.room }}</span
                      >
                    </section>
                    <span
                      ><i class="ri-lock-unlock-line"></i
                      >{{ Object.values(v.attendee_tags).join("，") }}
                    </span>
                    <span
                      class="special"
                      v-if="v.special_guest && v.special_guest.length"
                      ><i class="ri-user-3-line"></i>

                      <!-- <span
                        class="special-tags"
                        v-for="(item, index) in v.special_guest"
                        :key="index"
                        >{{ item.name }}</span
                      > -->
                      {{ v.special_guest.map((n) => n.name).join("，") }}
                    </span>
                    <span
                      class="d-flex"
                      v-if="v.file_array && v.file_array.length"
                    >
                      <i class="ri-folder-2-line"></i>
                      <div class="d-flex flex-column">
                        <a
                          :href="fv"
                          target="_blank"
                          v-for="(fv, fi) in v.file_array"
                          :key="fi"
                          class="mb-2"
                          style="text-decoration: underline; margin-top: 10px"
                          >{{ fv }}</a
                        >
                      </div>
                    </span>
                  </div>
                  <div class="i-tooltips">
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="Remove"
                      placement="left"
                    >
                      <i
                        @click="delMySchedule(v)"
                        class="ri-indeterminate-circle-line"
                      ></i>
                    </el-tooltip>
                  </div>
                </li>
              </ul>
            </section>
          </el-card>
        </div>
        <div v-else class="container690">
          <el-card>
            <div style="background:#fff;">
              <img
                src="@/assets/images/group/empty1.png"
                alt=""
                style="width:250px; margin: 100px auto; display: block"
              />
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" class="RightSidebar">
        <div v-if="single_schedule && single_schedule.length" class="cardStyle">
          <div class="mb-2 title">
            <i class="ri-voiceprint-line"></i> {{ $t("Events.583@live_now") }}
          </div>
          <div>
            <ul class="p-0 eventList">
              <li class="event mb-3" v-for="(v, i) in single_schedule" :key="i">
                <div class="title cursor-pointer" @click="toProgramDetail(v)">
                  {{ v.program_title }}
                </div>
                <div class="d-flex flex-column">
                  <span
                    v-if="v.broadcast_link"
                    @click="userHasPowerClickLink(v)"
                    class="link_blue"
                    >{{ $t("Events.dcf@click_here_to_view") }}</span
                  >
                  <a
                    class="link_blue mt-2"
                    @click="conferLinkLink(v.conference_link)"
                    v-if="v.conference_link"
                    target="__blank"
                    >{{ $t("Events.e6e@click_here_to_join_c") }}</a
                  >
                </div>
                <div class="dateTime">{{ v.time }}</div>
                <div class="dateTime">{{ v.time_zone }}</div>
              </li>
            </ul>
          </div>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  components: {},
  created() {
    this.getProgramList(3);
    this.getProgramList(5);
  },
  computed: {
    ...mapState({
      is_register: (state) => state.Process.is_register,
      mySchedule: (state) => state.Process.all_program,
      index: (state) => {
        if (state.Process.tabIndex >= 1) {
          return state.Process.tabIndex;
        } else {
          return 0;
        }
      },
      single_schedule: (state) => state.Process.single_schedule,
      typeId: (state) => state.Process.typeId,
    }),
  },

  methods: {
    ...mapMutations(["changeIndex"]),
    ...mapActions(["projectProgram"]),
    judgeCurrentType(item) {
      let type = item.model_type;
      return type == 1
        ? "Project"
        : type == 2
        ? "Event"
        : type == 3
        ? "Course"
        : type == 4
        ? "Meeting"
        : "";
    },
    conferLinkLink(link) {
      // console.log(link.indexOf("http"));
      if (link.indexOf("http") >= 0) window.open(link, "_blank");
      if (link.indexOf("http") < 0) window.open(`http://${link}`, "_blank");
    },
    // 调用列表
    async getProgramList(type) {
      let routerType = this.$route.query.type;
      let ajaxType = {
        1: "projectProgram",
        2: "eventProgram",
        3: "courseProgram",
        4: "meetingProgram",
      };
      await this.projectProgram([
        {
          [this.typeId[routerType]]: this.$route.query.id,
          type: type,
          time_zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        ajaxType[routerType],
      ]);
    },

    toProgramDetail(item) {
      // console.log('item', item);
      let path = {
        1: "/project-detail",
        2: "/event-detail",
        3: "/course-detail",
        4: "/meeting-details",
      };
      if (item.is_cron) {
        this.$router.push({
          path: path[item.model_type],
          query: {
            id: item.model_id,
            type: item.model_type,
          },
        });
      }
    },

    // 删除个人行行程
    async delMySchedule(item) {
      // console.log('mySchdule', item);
      let type = this.$route.query.type;
      let ajaxType = {
        1: "projectDeleteProgram",
        2: "eventDeleteProgram",
        3: "courseDeleteProgram",
        4: "meetingDeleteProgram",
      };
      let res = await this.$http[ajaxType[type]]({
        my_schedule_id: item.my_schedule_id,
        my_schedule_model_type: item.my_schedule_model_type,
      });
      if (res.status == 200) {
        this.getProgramList(3);
        this.getProgramList(5);
      }
    },

    async userHasPowerClickLink(item) {
      let ajaxType = {
        1: "userHasPowerClickLink",
        2: "eventUserHasPowerClickLink",
        3: "courseUserHasPowerClickLink",
        4: "meetingUserHasPowerClickLink",
      };
      let typeId = {
        1: "project_id",
        2: "event_id",
        3: "course_id",
        4: "meeting_id",
      };
      let type = this.$route.query.type;
      let token = localStorage.getItem("token");
      let getToken = token ? token : "";
      if (!getToken) {
        this.$router.push("/");
        return;
      }
      let res = await this.$http[ajaxType[type]]({
        [typeId[type]]: item.model_id,
        my_schedule_id: item.my_schedule_id,
        my_schedule_model_type: item.my_schedule_model_type,
        link_type: "broadcast_link",
      });
      if (res.status == 200) {
        // vimeo.com就在项目里，外链跳出去
        if (item.broadcast_link.indexOf("vimeo.com") > 0) {
          this.$router.push({
            path: type == 2 ? "/video-chat" : "/video",
            //modelType ==2，跳'video-chat'
            query: {
              id: item.model_id,
              my_schedule_id: item.my_schedule_id,
              type: this.$route.query.type,
              my_schedule_model_type: item.my_schedule_model_type,
              program_id: item.program_id,
              src: item.broadcast_link,
            },
          });
        } else {
          if (item.broadcast_link.indexOf("http") >= 0)
            window.open(item.broadcast_link, "_blank");
          if (item.broadcast_link.indexOf("http") < 0)
            window.open(`http://${item.broadcast_link}`, "_blank");
          // window.open(item.broadcast_link, '_blank')
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 576px) {
  .program-header {
    display: flex;
    align-items: center;

    > div i {
      font-size: 16px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 16px;
    }

    > div.program-btn {
      /* display: flex;
      flex-grow: 1;
      justify-content: flex-end; */

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 64px;
        position: relative;
        display: flex;
        // flex-direction: column;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 14px;
          left: 26px;
        }

        > div {
          display: flex;
          flex-direction: column;

          > h3 {
            font-size: 16px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }

            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }

            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 8px 0;
            line-height: 1;

            > i {
              font-size: 17px;
              padding-right: 10px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 22px;
            color: #ddd;
            cursor: pointer;
          }
        }

        /* .special {
        .special-tags {
          &::before {
            content: ",";
          }
        }
      } */
      }
    }
  }

  .RightSidebar {
    .cardStyle {
      width: 100%;
      padding: 15px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgba(43, 101, 143, 0.1);

      .title {
        font-size: 16px;
        color: #000;

        i {
          color: #50b5ff;
          padding-right: 10px;
        }
      }

      .eventList {
        .title {
          color: #333;
          font-size: 14px;
        }

        .dateTime {
          color: #666;
          font-size: 12px;
        }
      }
    }
  }

  .link_blue {
    text-decoration: underline;
    color: #50b5ff;
    cursor: pointer;
  }
}
@media (max-width: 576px) {
  .program-header {
    display: flex;
    align-items: center;

    > div i {
      font-size: 16px;
      color: #4cf0d5;
      padding-right: 15px;
    }

    > div p {
      color: #666;
      font-size: 16px;
    }

    > div.program-btn {
      /* display: flex;
      flex-grow: 1;
      justify-content: flex-end; */

      span {
        display: block;
        width: 90px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        background-color: #f5fbff;
        color: #50b5ff;
        cursor: pointer;
      }
    }
  }

  .program-content {
    p {
      line-height: 1;
      background-color: #f5fbff;
      padding: 15px;
      color: #666;
    }

    .program-list {
      > li {
        padding: 10px;
        padding-left: 30px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #6fc284;
          position: absolute;
          top: 12px;
          left: 10px;
        }

        > div {
          display: flex;
          flex-direction: column;
          .link_blue {
            text-decoration: underline;
            color: #50b5ff;
            padding: 0;
            cursor: pointer;
          }

          > h3 {
            font-size: 11px;
            color: #333;

            > .tag {
              padding: 0 12px;
              color: #6fc284;
              background-color: #f0f9f2;
              margin-left: 10px;
            }
            .MeetingTag {
              background-color: #ecf0f7;
              color: #3a67b1;
            }

            .EventTag {
              background-color: #f0f9f2;
              color: #6fc284;
            }

            .courseTag {
              background-color: #e6f4fb;
              color: #0097d7;
            }

            .projectTag {
              background-color: #eef9f8;
              color: #53c5b8;
            }
            .noneTag {
              display: none;
            }
          }

          span {
            color: #666;
            padding: 5px 0;
            font-size: 10px;
            line-height: 1;

            > i {
              font-size: 12px;
              padding-right: 10px;
            }
          }
        }

        > div.i-tooltips {
          i {
            font-size: 22px;
            color: #50b5ff;
            cursor: pointer;
          }
        }
        > div.i-remove {
          i {
            font-size: 22px;
            color: #ddd;
            cursor: pointer;
          }
        }
      }
    }
  }

  .RightSidebar {
    .cardStyle {
      width: 100%;
      padding: 15px;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 0px 20px 0px rgba(43, 101, 143, 0.1);

      .title {
        font-size: 10px;
        color: #000;

        i {
          color: #50b5ff;
          padding-right: 10px;
        }
      }

      .eventList {
        .title {
          color: #333;
          font-size: 10px;
        }

        .dateTime {
          color: #666;
          font-size: 8px;
        }
      }
    }
  }

  .link_blue {
    text-decoration: underline;
    color: #50b5ff;
    cursor: pointer;
  }
}
</style>